
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
#questionaryProblem-toggle {
	width: auto;
	padding: 12px;
	display: flex;
	float: right;
	border-radius: 3px;
	background-color: rgba(0, 0, 0, 0);
	cursor: pointer;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	align-items: center;
	text-align: center;
	text-decoration: none;
	color: #FFFFFF;
	letter-spacing: 0.75px;
	text-transform: uppercase;
	transition: background-color 150ms ease-out;
}
[dir="rtl"] #questionaryProblem-toggle {
	float: left;
}

#questionaryProblem-toggle:hover,
#questionaryProblem-toggle:focus { background-color: rgba(0, 0, 0, 0.2); }

#questionaryProblem-modal {
	padding: 30px 10px;
	position:absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 998;
	background-color: rgba(0,0,0,0.5);
}

#questionaryProblem {
	margin: 0 auto auto auto;
	min-width: 230px;
	width: calc(100% - 10px);
	max-width: 400px;
	padding: 8px;
	border: 1px solid #004B90;
	z-index: 999;
	background-color: #FFFFFF;
	color: #3A3A3A;
	border-radius: 3px;
	box-sizing: border-box;
}

#questionaryProblem textarea {
	width: calc(100% - 6px);
	min-height: 8rem;
	margin-bottom: 10px;
	padding: 2px;
	display: block;
	border: 1px solid #CCCCCC;
	border-radius: 3px;
	resize: vertical;
}

#questionaryProblem input[type="button"].btn-cawi { background: #1964AA; }
